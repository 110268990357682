<template>
  <section id="nosotros-ayudamos">
    <div class="container jumbotron">
      <div class="row justify-content-center">
        <div class="col-10">
          <h1 class="section-title">Política de cookies.</h1>
          <div>
            <p>
              En la web https://ibancar.com (en adelante, el Sitio Web)
              utilizamos cookies para facilitar la relación de los visitantes
              con nuestro contenido y para permitir elaborar estadísticas sobre
              las visitantes que recibimos.<br />
              Le informamos que podemos utilizar cookies con la finalidad de
              facilitar su navegación a través del Sitio Web, distinguirle de
              otros usuarios, proporcionarle una mejor experiencia en el uso del
              mismo, e identificar problemas para mejorar nuestro Sitio Web.
              Asimismo, en caso de que preste su consentimiento, utilizaremos
              cookies que nos permitan obtener más información acerca de sus
              preferencias y personalizar nuestro Sitio Web de conformidad con
              sus intereses individuales.
            </p>
            <h5>¿Qué son las cookies?</h5>
            <p>
              Se denominan cookies a unos pequeños archivos que se graban en el
              navegador utilizado por cada visitante de nuestra web para que el
              servidor pueda recordar la visita de ese usuario con posterioridad
              cuando vuelva a acceder a nuestros contenidos. Esta información no
              revela su identidad, ni dato personal alguno, ni accede al
              contenido almacenado en su pc, pero sí que permite a nuestro
              sistema identificarle a usted como un usuario determinado que ya
              visitó la web con anterioridad, visualizó determinadas páginas,
              etc. y además permite guardar sus preferencias personales e
              información técnica como por ejemplo las visitas realizadas o
              páginas concretas que visite.<br />
              En cumplimiento de la Directiva 2009/136/CE, desarrollada en
              nuestro ordenamiento por el apartado segundo del artículo 22 de la
              Ley de Servicios de Sociedad de la Información, siguiendo las
              directrices de la Agencia Española de Protección de Datos,
              procedemos a informarle detalladamente del uso que se realiza en
              nuestra web.<br />
              La presente política de cookies tiene por finalidad informarle de
              manera clara y precisa sobre las cookies que se utilizan en
              nuestro Sitio Web (la “Política de Cookies”). Respecto de las
              cookies de terceros, es decir aquellas que son ajenas a nuestro
              sitio web, no podemos hacernos responsables del contenido y
              veracidad de las políticas de privacidad que ellos incluyen por lo
              que la información que le ofrecemos es siempre con referencia a la
              fuente.<br />
              A continuación, se realiza una clasificación de las cookies en
              función de una serie de categorías. No obstante es necesario tener
              en cuenta que una misma cookie puede estar incluida en más de una
              categoría.
            </p>
            <h5>Tipos de cookies según la entidad que las gestiona</h5>
            <p>
              - Cookies propias: Son aquéllas que se envían al equipo terminal
              del usuario desde un equipo o dominio gestionado por el propio
              editor y desde el que se presta el servicio solicitado por el
              usuario.<br />
              - Cookies de terceros: Son aquéllas que se envían al equipo
              terminal del usuario desde un equipo o dominio que no es
              gestionado por el editor, sino por otra entidad que trata los
              datos
            </p>
            <h5>
              Tipos de cookies según el plazo de tiempo que permanecen activas
            </h5>
            <p>
              - Cookies de sesión: Son un tipo de cookies diseñadas para recabar
              y almacenar datos mientras el usuario accede a una página web.<br />
              - Cookies persistentes: Son un tipo de cookies en el que los datos
              siguen almacenados en el terminal y pueden ser accedidos y
              tratados durante un periodo definido por el responsable de la
              cookie, y que puede ir de unos minutos a varios años.
            </p>
            <h5>Tipos de cookies según su finalidad</h5>
            <p>
              - Cookies técnicas: Aquéllas que permiten al usuario la navegación
              a través de una página web, plataforma o aplicación y la
              utilización de las diferentes opciones o servicios que en ella
              existan como, por ejemplo, controlar el tráfico y la comunicación
              de datos, identificar la sesión, acceder a partes de acceso
              restringido, recordar los elementos que integran un pedido,
              realizar el proceso de compra de un pedido, realizar la solicitud
              de inscripción o participación en un evento, utilizar elementos de
              seguridad durante la navegación, almacenar contenidos para la
              difusión de videos o sonido o compartir contenidos a través de
              redes sociales. <br />- Cookies de personalización: Son aquéllas
              que permiten al usuario acceder al servicio con algunas
              características de carácter general predefinidas en función de una
              serie de criterios en el terminal del usuario como por ejemplo
              serian el idioma, el tipo de navegador a través del cual accede al
              servicio, la configuración regional desde donde accede al
              servicio, etc. <br />- Cookies publicitarias: Son aquéllas que
              permiten la gestión, de la forma más eficaz posible, de los
              espacios publicitarios que, en su caso, el editor haya incluido en
              una página web, aplicación o plataforma desde la que presta el
              servicio solicitado en base a criterios como el contenido editado
              o la frecuencia en la que se muestran los anuncios. <br />-
              Cookies de publicidad comportamental: Son aquéllas que permiten la
              gestión, de la forma más eficaz posible, de los espacios
              publicitarios que, en su caso, el editor haya incluido en una
              página web, aplicación o plataforma desde la que presta el
              servicio solicitado. Estas cookies almacenan información del
              comportamiento de los usuarios obtenida a través de la observación
              continuada de sus hábitos de navegación, lo que permite
              desarrollar un perfil específico para mostrar publicidad en
              función del mismo. <br />- Cookies de análisis: Son aquéllas que
              permiten al responsable de las mismas, el seguimiento y análisis
              del comportamiento de los usuarios de los sitios web a los que
              están vinculadas. La información recogida mediante este tipo de
              cookies se utiliza en la medición de la actividad de los sitios
              web, aplicación o plataforma y para la elaboración de perfiles de
              navegación de los usuarios de dichos sitios, aplicaciones y
              plataformas, con el fin de introducir mejoras en función del
              análisis de los datos de uso que hacen los usuarios del
              servicio.<br />
              Respecto al tratamiento de datos recabados a través de las cookies
              de análisis, a pesar de que no están exentas del deber de obtener
              un consentimiento informado para su uso, es poco probable que
              representen un riesgo para la privacidad de los usuarios siempre
              que se trate de cookies de primera parte, que traten datos
              agregados con una finalidad estrictamente estadística, que se
              facilite información sobre sus uso y se incluya la posibilidad de
              que los usuarios manifiesten su negativa sobre su utilización.<br />
              Actualmente, la mayoría de los navegadores vienen configurados por
              defecto para bloquear la instalación de cookies de publicidad o
              terceros en su equipo. El usuario puede ampliar las restricciones
              de origen, impidiendo la entrada de cualquier tipo de cookie, o
              eliminar dichas restricciones, aceptando la entrada de cualquier
              tipo de cookies. Si está interesado en admitir cookies de
              publicidad o de terceros, podrá configurar su navegador a tal
              fin.<br />
              La aceptación realizada por el usuario, haciendo click en el botón
              de ACEPTAR mostrado en la información inicial sobre cookies,
              implica que está consintiendo expresamente al responsable para su
              utilización, pudiendo ejercer sus derechos y revocar su
              consentimiento en cualquier momento, a través de solicitud a
              IBANCAR WORLD SL.<br />
              A continuación, le informamos detalladamente todas las cookies que
              podrían Fllegar a instalarse desde nuestro sitio web. En función
              de su navegación, de la configuración de su navegador y de la
              aceptación o rechazo de las mismas, podrán instalarse todas o sólo
              algunas de ellas:<br />
            </p>
            <table class='cookies'>
              <thead>
                <tr>
                  <th>Cookie</th>
                  <th>Tipo</th>
                  <th>Expira</th>
                  <th style='width:50%'>Finalidad</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CONSENT</td>
                  <td>HTTP</td>
                  <td>2 años</td>
                  <td>
                    Utilizada para detectar si el visitante ha aceptado la
                    categoría de marketing en el banner de cookies.
                  </td>
                </tr>
                <tr>
                  <td>rc::a</td>
                  <td>HTML</td>
                  <td>Persistent</td>
                  <td>
                    Esta cookie se utiliza para distinguir entre humanos y bots.
                  </td>
                </tr>
                <tr>
                  <td>rc::c</td>
                  <td>HTTP</td>
                  <td>Session</td>
                  <td>
                    Esta cookie se utiliza para distinguir entre humanos y bots.
                  </td>
                </tr>
                <tr>
                  <td>CookieConsent</td>
                  <td>HTTP</td>
                  <td>1 año</td>
                  <td>
                    Almacena el estado de consentimiento de cookies del usuario
                    para el dominio actual.
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              Es posible que actualicemos la Política de Cookies de nuestro
              Sitio Web, por ello le recomendamos revisar esta política cada vez
              que acceda a nuestro Sitio Web con el objetivo de estar
              adecuadamente informado sobre cómo y para qué usamos las
              cookies<br />
              La finalidad de las cookies es la de facilitar al Usuario un
              acceso más rápido a los Servicios seleccionados. Si usted no desea
              que se guarden cookies en su navegador o prefiere recibir una
              información cada vez que una cookie solicite instalarse, puede
              configurar sus opciones de navegación para que se haga de esa
              forma. La mayor parte de los navegadores permiten la gestión de
              las cookies de 3 formas diferentes:
              <br />- Las cookies son siempre rechazadas; <br />- El navegador
              pregunta si el usuario desea instalar cada cookie; <br />- Las
              cookies son siempre aceptadas; <br />Su navegador también puede
              incluir la posibilidad de seleccionar con detalle las cookies que
              desea que se instalen en su ordenador. En concreto, el usuario
              puede normalmente aceptar alguna de las siguientes opciones:
              <br />- Rechazar las cookies de determinados dominios; <br />-
              Rechazar las cookies de terceros; <br />- Aceptar cookies como no
              persistentes (se eliminan cuando el navegador se cierra); <br />-
              Permitir al servidor crear cookies para un dominio diferente. Para
              permitir, conocer, bloquear o eliminar las cookies instaladas en
              su equipo puede hacerlo mediante la configuración de las opciones
              del navegador instalado en su ordenador. Puede encontrar
              información sobre cómo configurar los navegadores más usados en
              las siguientes ubicaciones: <br />• Internet Explorer:
              Herramientas -> Opciones de Internet -> Privacidad ->
              Configuración. Para más información, puede consultar el soporte de
              Microsoft o la Ayuda del navegador. <br />• Firefox: Herramientas
              -> Opciones -> Privacidad -> Historial -> Configuración
              Personalizada. Para más información, puede consultar el soporte de
              Mozilla o la Ayuda del navegador. <br />• Chrome: Configuración ->
              Mostrar opciones avanzadas -> Privacidad -> Configuración de
              contenido. Para más información, puede consultar el soporte de
              Google o la Ayuda del navegador. <br />• Safari: Preferencias ->
              Seguridad. Para más información, puede consultar el soporte de
              Apple o la Ayuda del navegador.<br />
              Si ha aceptado cargar las cookies de Google Analytics, también
              puede revocar en cualquier momento dicha aceptación utilizando el
              "Complemento de inhabilitación para navegadores de Google
              Analytics", accediendo a la siguiente página web y siguiendo las
              instrucciones que ahí se detallan:<br />
              <a href="https://tools.google.com/dlpage/gaoptout?hl=es"
                >https://tools.google.com/dlpage/gaoptout?hl=es</a
              >
            </p>
          </div>
          <hr />
          <p>
            <small
              ><i class="fa fa-calendar-check-o" aria-hidden="true"></i>
              <i
                >{{ new Date().getFullYear() }} Adaptado a Reglamento General de
                Protección de Datos (RGPD)</i
              ></small
            >
          </p>
        </div>
        <!-- /row1 -->
      </div>
    </div>
    <!-- /container -->
  </section>
  <!-- /section -->
</template>

<script>
import { useMeta } from 'vue-meta'
export default {
  name: "Cookies",
  setup() {
    useMeta({
      title: 'Cookies',
      link: [
        { href: 'https://ibancar.com/politica-cookies', rel: 'alternate', hreflang: 'es-es' },
        { href: 'https://ibancar.mx/politica-cookies', rel: 'alternate', hreflang: 'es-MX' }
      ]
    })
  }
};
</script>

<style scoped lang="scss">
.jumbotron {
  padding: 25px 0 0 25px;
  margin-top: 90px;
  background-color: #fff;
}
.cookies td ,  .cookies th{
	border: 1px solid black; 
	padding: 2%
}
.cookies thead{
	background: rgb(186, 186, 186)
}
.cookies{
	margin-bottom: 3%;
	margin-top: 3%
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {
}

@include media-breakpoint-between(md, lg) {
}

@include media-breakpoint-between(xl, xxl) {
}
</style>